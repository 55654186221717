import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const NeedForPatentAttorney = () => {
    const seo = {
        metaDesc: 'Need For Patent Attorney or Agent - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Need For Patent Attorney or Agent - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <h1 id="applicationprocess&quot;">1. Will I need a patent attorney or agent for the application process?</h1>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>&nbsp;For filing, you can draft and file your own applications, but patent attorneys and agents are experts and can help you draft the application in a professional manner.</li><li>Responding to communications from the Patent Office reviews will likely require professional help to&nbsp; move the application forward.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <p><strong>Table of Contents</strong></p>
                        <p><strong>1.&nbsp; <a href="#applicationprocess" class="d-clr">Will I need a patent attorney or agent for the application process?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1&nbsp;&nbsp;&nbsp; <a href="#difference" class="d-clr">What’s the difference between a patent attorney and agent?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.2&nbsp;&nbsp;&nbsp; <a href="#registered" class="d-clr">What is the process to become a USPTO registered patent attorney or patent agent?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.3&nbsp;&nbsp;&nbsp; <a href="#paperwork" class="d-clr">Is there paperwork that an attorney may ask me to sign for his/her services?</a></strong></p>
                    </div>
                    <img src="/assets/img/banner/personal-financial-advisor-using-tablet_BYeGZEAVo-e1602427385376.jpg" width="100%" alt="" />
                    <p>
                        There is nothing that prohibits an inventor from preparing and filing a patent application without a lawyer.  However, the prosecution process is complex and requires substantial research, and the patent application must be written in a definite format.
                        <br /><br />
                        Because the patent application process is complex, you should contact a registered patent attorney or agent for guidance. Only USPTO registered attorneys and agents may help others obtain patents. A roster of patent attorneys and agents registered to practice before the USPTO is on the USPTO web site. All attorneys and agents practicing before the USPTO in a trademark or patent matters are subject to the USPTO Rules of Professional Conduct set forth and disciplinary jurisdiction.
                    </p>
                    <h2 id="difference">1.1 What’s the difference between a patent attorney and agent?</h2>
                    <table class="table table-bordered mt-3"><tbody><tr><td width="623"><ul><li>Both have taken and passed the PTO examination for registration.</li><li>Both are required to have some technical education in science or engineering.</li><li>But only a patent lawyer can provide legal advice, including on patent licensing or patent infringement before a court.</li><li>Power of Attorney Form</li></ul></td></tr></tbody></table>
                    <p>There are many similarities: both have taken and passed the PTO examination for registration, both are required to have some technical education in science or engineering, and both have competency in the requirements for preparing, filing, and prosecuting patent applications with the USPTO.</p>
                    <p>The major difference between patent attorneys and patent agents lies in the ability to practice law. A patent attorney has attended law school, taken, and passed an examination for registration to practice as a lawyer in that state. A patent agent is not a lawyer and cannot provide legal advice, including advice on patent licensing or patent infringement. Only lawyers can represent you in any legal proceedings involving state or federal court.</p>
                    <img src="/assets/img/banner/1-e1602427134246.jpg" width="100%" alt="" />
                    <p>
                        Due to the difference in legal education, a patent agent is often less expensive than a patent attorney.  However, you should check the billing rate and discuss your concerns with them.
                        <br /><br />
                        Both can prepare a patent application and conduct USPTO prosecution, but only an attorney can practice law.  For example, an attorney can conduct patent litigation in the courts while an agent cannot.
                    </p>
                    <h2 id="registered">1.2 What is the process to become a USPTO registered patent attorney or patent agent?</h2>
                    <p>Prior to admission to the USPTO registry, the applicant must show compliance with USPTO rules, including showing good moral character and possessing particular legal, scientific, and technical qualifications such as a college education in engineering or the physical sciences. The applicant must also pass a rigorous exam to ensure that he/she is familiar with USPTO practices and procedures.</p>
                    <h2 id="paperwork">1.3 Is there paperwork that an attorney may ask me to sign for his/her services?</h2>
                    <img src="/assets/img/banner/power-of-attorney_GkbOZwPO-e1602427229219.jpg" width="100%" alt="" />
                    <p>
                        The Power of Attorney that shows you have appointed him/her to represent you and handle the patent application process on your behalf.
                        <br />
                        When working with an attorney, he/she may ask you to sign a power of attorney. By granting a power of attorney, the person with this power can represent or handle a patent application on your behalf. This means the person with the power of attorney has the authority to communicate with the USPTO. It does not give the person any ownership rights to your invention. This relationship can be terminated at any time when you grant the power of attorney to someone else.
                        Note that if you are represented by a patent attorney or agent of record, the PTO will only talk to your attorney or agent and not answer inquiries from both you and your attorney/agent.
                    </p>

                </div>

            </section>
        </Layout>
    )
};

export default NeedForPatentAttorney;